import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MainLayout, PostCard, PaginationHelper, PostsRow } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Row, Col, Divider, Typography, Tag } from 'antd'
const { Title, Text } = Typography
import { Parallax } from 'rc-scroll-anim'
/**
* About page
*
*
*/
const About = ({ data, location, pageContext }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const image = data.joeImage

    const page = {
        page: {
            title: `About`,
            meta_title: ``,
            meta_description: `I'm a developer, technologist, entrepreneur, indie hacker, etc. Some combo of a lot of things really.`,
            name: ``,
            feature_image: ``,
            description: `I'm a developer, technologist, entrepreneur, indie hacker, etc. Some combo of a lot of things really.`,
        },
    }

    return (
        <>
            <MetaData location={location} data={page} />
            <MainLayout className={`page`}>
                <Row
                    gutter={[16, 24]}
                    justify={`center`}
                >
                    <Col
                        xs={24}
                        sm={24}
                        md={14}
                        lg={12}
                        xl={12}
                        xxl={5}
                    >
                        <Typography>
                            <Title>
                                About
                            </Title>
                        </Typography>

                        {/*<HtmlParser html={page.html} />*/}

                        <Title level={2}>Hi, I&apos;m Joe.</Title>
                        <Typography>
                            I&apos;m a developer, technologist, entrepreneur, indie hacker, etc. Some combo of a lot of things really.
                        </Typography>
                        <br />
                        <Typography>
                            I have a BS in Informatics from the University of Washington. After getting enough cloudy days to last me my lifetime, I moved back to Southern California. I've mostly worked as a freelance developer and consultant while shipping many side projects along the way.
                        </Typography>
                        <br />
                        <Typography>
                            I like to bring it back to the simple things in life when I'm not working on digital products. Hiking, cooking, fermentation—to name a few. Lately, I've been trying to master the art of a good whole wheat sourdough loaf.

                        </Typography>
                    </Col>
                    <Col
                        xs={16}
                        sm={16}
                        md={8}
                        lg={7}
                        xl={7}
                        xxl={5}
                    >
                        <Img
                            fluid={image.childImageSharp.fluid}
                            alt={`Featured image for page`}
                        />
                    </Col>
                </Row>

            </MainLayout>
        </>
    )
}

About.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.object,
        }),
        joeImage: PropTypes.object,
        location: PropTypes.object,
        pageContext: PropTypes.object,
    }),
}

export default About

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        title
      }
    }
    joeImage: file(relativePath: { eq: "joe-czubiak.jpg" }) {
        childImageSharp {
            fluid (quality: 100, maxHeight: 683, maxWidth: 512)
            {
                base64
                aspectRatio
                src
                srcSet
                sizes
            }
        }
    }
  }
 `
